import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ArticlePreview from "../components/articlepreview";
import SEO from "../components/seo";

export default function News({ data }) {
  const rest = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <SEO title="News" />
      {rest.map(({ node }) => (
        <>
          <ArticlePreview
            size="small"
            key={node.id}
            meta={node.frontmatter}
            fields={node.fields}
          />
          <hr style={{ marginBottom: "30px" }} />
        </>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
      filter: {
        frontmatter: {unlisted: {ne: true}}
      }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
            category
          }
          frontmatter {
            title
            tagline
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
        }
      }
    }
  }
`;
